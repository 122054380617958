<template>
    <section id="sec-5">
        <div class="container">
            <div class="caption">
                <h2>Хочешь получить эксклюзивные условия?</h2>
            </div>
            <div class="txt-center">
                <button class="btn" @click="scrollToSection('#reg-form')">
                    СТАТЬ ПАРТНЕРОМ
                </button>
            </div>
            <div class="caption">
                <h2>Подписывайтесь на наши социальные сети<br>
                    и добро пожаловать в SAPPHIREBET PARTNERS</h2>
            </div>
            <div class="txt-center link-to-soc">
                <a href="https://t.me/@sapphirebet_partners_support" target="_blank" class="a-btn">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M31.75 20.8333L23.4167 29.1666L35.9167 41.6666L44.25 8.33325L6.75 22.9166L15.0833 27.0833L19.25 39.5833L25.5 31.2499"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Telegram
                </a>
                <a href="#" class="a-btn">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.83325 16.6666C8.83325 14.4564 9.71123 12.3368 11.274 10.774C12.8368 9.21123 14.9564 8.33325 17.1666 8.33325H33.8333C36.0434 8.33325 38.163 9.21123 39.7258 10.774C41.2886 12.3368 42.1666 14.4564 42.1666 16.6666V33.3333C42.1666 35.5434 41.2886 37.663 39.7258 39.2258C38.163 40.7886 36.0434 41.6666 33.8333 41.6666H17.1666C14.9564 41.6666 12.8368 40.7886 11.274 39.2258C9.71123 37.663 8.83325 35.5434 8.83325 33.3333V16.6666Z"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M34.875 15.625V15.6458M19.25 25C19.25 26.6576 19.9085 28.2473 21.0806 29.4194C22.2527 30.5915 23.8424 31.25 25.5 31.25C27.1576 31.25 28.7473 30.5915 29.9194 29.4194C31.0915 28.2473 31.75 26.6576 31.75 25C31.75 23.3424 31.0915 21.7527 29.9194 20.5806C28.7473 19.4085 27.1576 18.75 25.5 18.75C23.8424 18.75 22.2527 19.4085 21.0806 20.5806C19.9085 21.7527 19.25 23.3424 19.25 25Z"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    Instagram
                </a>
            </div>
        </div>

    </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'Section5Com',
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
        link() {
            return 'https://sapphirebet.partners/sign-up/' + window.location.search
        }
    },
    data() {
        return {
        }
    },
    methods: {
        scrollToSection(sectionId) {
            const targetElement = document.querySelector(sectionId);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>
<style lang="scss">
#sec-5 {
    padding: 0 20px;
    padding-top: 240px;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 576px) {
        padding-top: 120px;
    }

    .btn {
        width: 427px;
        margin-bottom: 200px;
    }

    .a-btn {
        border-radius: 56px;
        background: #172B9B;
        text-decoration: none;
        color: #FFF;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        padding: 8px 32px;

        @media screen and (max-width: 576px) {
            font-size: 16px;
            padding: 5px 20px;
        }

        svg {
            margin-right: 30px;

            @media screen and (max-width: 576px) {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;

            @media screen and (max-width: 576px) {
                margin-right: 15px;
            }
        }
    }
}
</style>
  