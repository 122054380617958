<template>
    <section id="sec-1">
        <div class="container">
            <div class="header-line">
                <div class="logo">
                    <img src="../../assets/logo.svg" v-if="!isTablet">
                    <img src="../../assets/logo-m.svg" v-else>
                </div>
                <nav v-if="!isTablet">
                    <a v-for="(m, ind) in menu" :key="m.path + ind" :href="m.path" @click="scrollToSection(m.path)">{{
                        m.name }}</a>
                </nav>
                <div class="div-btn">
                    <button class="btn" v-if="!isTablet" @click="scrollToSection('#reg-form')">
                        СТАТЬ ПАРТНЕРОМ
                    </button>
                    <button class="ghost" v-else @click="showMenu = true">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 7.5H25M5 15H25M5 22.5H25" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>

                    </button>
                </div>
            </div>
            <div class="sap-1">
                <img src="../../assets/sap-s-1_1.png">
            </div>
            <div class="welcome">
                <h1>Эклюзивная партнерская программа от прямого рекламодателя в нише iGaming</h1>
                <p>Регистрируйся прямо сейчас и монетизируй свой трафик!</p>
                <button class="btn" @click="scrollToSection('#reg-form')">
                    СТАТЬ ПАРТНЕРОМ
                </button>
            </div>
        </div>
        <img src="../../assets/sap-s-1_2.png" class="sap-2" v-if="!isTablet">
        <template v-if="isTablet">
            <div class="mobile-menu" v-if="showMenu">
                <div class="right">
                    <button class="ghost" @click="showMenu = false">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 8L8 24M8 8L24 24" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>

                    </button>
                </div>
                <ul>
                    <li v-for="(m, ind) in menu" :key="m.path + ind">
                        <a :href="m.path" @click="scrollToSection(m.path); showMenu = false">{{
                            m.name }}</a>
                    </li>
                </ul>
            </div>
        </template>
    </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'Section1Com',
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
        link() {
            return 'https://sapphirebet.partners/sign-up/' + window.location.search
        }
    },
    data() {
        return {
            showMenu: false,
            menu: [
                { name: 'Главная', path: '#sec-1' },
                { name: 'Преимущества', path: '#sec-2' },
                { name: 'Выплаты', path: '#sec-3' },
                { name: 'Gambling & Betting', path: '#sec-4' },
                { name: 'Контакты', path: '#sec-5' },
            ]
        }
    },
    methods: {
        scrollToSection(sectionId) {
            const targetElement = document.querySelector(sectionId);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>
<style lang="scss">
#sec-1 {
    padding: 0 20px;
    position: relative;
    z-index: 5;

    .header-line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            height: 125px;
            width: 203px;

            @media screen and (max-width: 768px) {
                height: 165px;
                width: 91px;
            }

            img {
                position: relative;
                left: -65px;

                @media screen and (max-width: 768px) {
                    left: -35px;
                }
            }
        }

        nav {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10vw;

            a {
                font-family: Helvetica;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #fff;
                text-decoration: none;
            }
        }
    }

    .div-btn {
        width: 203px;

        @media screen and (max-width: 768px) {
            width: unset;
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }

    }

    .sap-1 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: -30px;

        @media screen and (max-width: 768px) {
            justify-content: flex-start;
            margin-bottom: -130px;
        }

        img {
            position: relative;
            left: -7vw;
            width: 163px;
            height: 103px;
            top: -3vw;

            @media screen and (max-width: 768px) {
                left: -25vw;
                top: -15vw;
            }
        }
    }

    .welcome {
        width: 60%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        h1 {
            color: #FFF;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 768px) {
                font-size: 40px;
            }
        }

        p {
            color: #9F9FB8;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 90px;
            margin-top: 30px;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        .btn {
            width: 427px;
            z-index: 2;
            position: relative;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .sap-2 {
        position: absolute;
        right: -20px;
        bottom: -10vw;
        width: 232px;
        height: 156px;
        z-index: 1;
    }

    .mobile-menu {
        position: fixed;
        left: 0;
        width: 100%;
        top: 0;
        z-index: 9;
        height: 100%;
        background: rgba(0, 0, 0, .9);
        padding: 10px;

        .right {
            display: flex;
            justify-content: flex-end;
        }

        ul {
            list-style: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                a {
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}
</style>
  