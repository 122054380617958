<template>
    <section id="sec-2">
        <div class="container">
            <div class="caption">
                <h2>ПРЕИМУЩЕСТВА</h2>
            </div>
            <div class="grid">
                <div class="grid__coll">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.5 14.5833C24.3949 14.5833 23.3351 14.1443 22.5537 13.3629C21.7723 12.5815 21.3333 11.5217 21.3333 10.4167C21.3333 9.3116 21.7723 8.25179 22.5537 7.47039C23.3351 6.68899 24.3949 6.25 25.5 6.25C26.6051 6.25 27.6649 6.68899 28.4463 7.47039C29.2277 8.25179 29.6667 9.3116 29.6667 10.4167C29.6667 11.5217 29.2277 12.5815 28.4463 13.3629C27.6649 14.1443 26.6051 14.5833 25.5 14.5833ZM25.5 14.5833V22.9167M25.5 22.9167C27.1576 22.9167 28.7473 23.5751 29.9194 24.7472C31.0915 25.9194 31.75 27.5091 31.75 29.1667C31.75 30.8243 31.0915 32.414 29.9194 33.5861C28.7473 34.7582 27.1576 35.4167 25.5 35.4167C23.8424 35.4167 22.2527 34.7582 21.0806 33.5861C19.9085 32.414 19.25 30.8243 19.25 29.1667C19.25 27.5091 19.9085 25.9194 21.0806 24.7472C22.2527 23.5751 23.8424 22.9167 25.5 22.9167ZM14.4583 37.0833L20.2917 32.9167M36.5417 37.0833L30.7083 32.9167M6.75 39.5833C6.75 40.6884 7.18899 41.7482 7.97039 42.5296C8.75179 43.311 9.8116 43.75 10.9167 43.75C12.0217 43.75 13.0815 43.311 13.8629 42.5296C14.6443 41.7482 15.0833 40.6884 15.0833 39.5833C15.0833 38.4783 14.6443 37.4185 13.8629 36.6371C13.0815 35.8557 12.0217 35.4167 10.9167 35.4167C9.8116 35.4167 8.75179 35.8557 7.97039 36.6371C7.18899 37.4185 6.75 38.4783 6.75 39.5833ZM35.9167 39.5833C35.9167 40.6884 36.3557 41.7482 37.1371 42.5296C37.9185 43.311 38.9783 43.75 40.0833 43.75C41.1884 43.75 42.2482 43.311 43.0296 42.5296C43.811 41.7482 44.25 40.6884 44.25 39.5833C44.25 38.4783 43.811 37.4185 43.0296 36.6371C42.2482 35.8557 41.1884 35.4167 40.0833 35.4167C38.9783 35.4167 37.9185 35.8557 37.1371 36.6371C36.3557 37.4185 35.9167 38.4783 35.9167 39.5833Z"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h3>Один продукт и три направления</h3>
                    <span>Казино<br>
                        Букмекерская контора<br>
                        E-sport</span>
                </div>
                <div class="grid__coll rowspan-2" v-if="!isMobyle"></div>
                <div class="grid__coll">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.0833 41.6667H35.9167M19.25 33.3333V41.6667M31.75 33.3333V41.6667M6.75 10.4167C6.75 9.86414 6.96949 9.33424 7.36019 8.94354C7.75089 8.55284 8.2808 8.33334 8.83333 8.33334H42.1667C42.7192 8.33334 43.2491 8.55284 43.6398 8.94354C44.0305 9.33424 44.25 9.86414 44.25 10.4167V31.25C44.25 31.8025 44.0305 32.3324 43.6398 32.7231C43.2491 33.1138 42.7192 33.3333 42.1667 33.3333H8.83333C8.2808 33.3333 7.75089 33.1138 7.36019 32.7231C6.96949 32.3324 6.75 31.8025 6.75 31.25V10.4167Z"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.1667 25L23.4167 18.75L27.5834 22.9167L33.8334 16.6667" stroke="white" stroke-width="3"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h3>Промо</h3>
                    <span>Высококонверсионные лендинги и тематические промо-материалы от SapphireBet</span>
                </div>
                <div class="grid__coll">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 18.75C13 22.0652 14.317 25.2446 16.6612 27.5888C19.0054 29.933 22.1848 31.25 25.5 31.25C28.8152 31.25 31.9946 29.933 34.3388 27.5888C36.683 25.2446 38 22.0652 38 18.75C38 15.4348 36.683 12.2554 34.3388 9.91117C31.9946 7.56696 28.8152 6.25 25.5 6.25C22.1848 6.25 19.0054 7.56696 16.6612 9.91117C14.317 12.2554 13 15.4348 13 18.75Z"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M25.5 31.25L32.5834 43.5208L35.9125 36.7854L43.4084 37.2687L36.325 25M14.6709 25L7.58752 37.2708L15.0834 36.7854L18.4125 43.5187L25.4959 31.25"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h3>Модели вознаграждения</h3>
                    <span>CPA, Revshare, Hybrid</span>
                </div>
                <div class="grid__coll">
                    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M42.1666 22.9166C41.6571 19.2504 39.9563 15.8534 37.3262 13.2489C34.6962 10.6444 31.2827 8.97689 27.6117 8.50321C23.9407 8.02953 20.2158 8.77598 17.0108 10.6276C13.8057 12.4792 11.2984 15.3332 9.87492 18.75M8.83325 10.4166V18.75H17.1666M8.83325 27.0833C9.43051 31.3921 11.6698 35.3035 15.0833 38M23.4166 31.25H27.5833C28.1358 31.25 28.6657 31.4695 29.0564 31.8602C29.4471 32.2509 29.6666 32.7808 29.6666 33.3333V35.4166C29.6666 35.9692 29.4471 36.4991 29.0564 36.8898C28.6657 37.2805 28.1358 37.5 27.5833 37.5H25.4999C24.9474 37.5 24.4175 37.7195 24.0268 38.1102C23.6361 38.5009 23.4166 39.0308 23.4166 39.5833V41.6666C23.4166 42.2192 23.6361 42.7491 24.0268 43.1398C24.4175 43.5305 24.9474 43.75 25.4999 43.75H29.6666M35.9166 31.25V35.4166C35.9166 35.9692 36.1361 36.4991 36.5268 36.8898C36.9175 37.2805 37.4474 37.5 37.9999 37.5H40.0833M42.1666 31.25V43.75"
                            stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h3>Поддержка</h3>
                    <span>Персональный менеджер 24/7</span>
                </div>
            </div>
            <div class="txt-center">
                <button class="btn" @click="scrollToSection('#reg-form')">
                    СТАТЬ ПАРТНЕРОМ
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'Section2Com',
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        }
    },
    data() {
        return {
        }
    },
    methods: {
        scrollToSection(sectionId) {
            const targetElement = document.querySelector(sectionId);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>
<style lang="scss">
#sec-2 {
    padding: 0 20px;
    padding-top: 200px;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 576px) {
        padding-top: 140px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 100px;
        margin-bottom: 100px;

        @media screen and (max-width: 576px) {
            grid-template-columns: 100%;
            row-gap: 40px;
            margin-bottom: 70px;
        }

        &__coll {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;

            &:nth-child(3) {
                h3 {
                    height: 68px;
                }
            }

            &.rowspan-2 {
                grid-row: 1/3;
                grid-column: 2;
                background-image: url("../../assets/logo-bg.svg");
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0.5;
            }

            h3 {
                margin: 0;
                color: #FFF;
                text-align: center;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media screen and (max-width: 576px) {
                    font-size: 22px;
                }
            }

            span {
                text-align: center;
                color: #9F9FB8;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
    }

    .btn {
        width: 427px;
    }
}
</style>
  