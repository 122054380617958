<template>
    <section id="sec-6">
        <img src="../../assets/sap-s-6_1.png" class="sap-1" v-if="!isMobyle">
        <div class="container">
            <div class="caption">
                <h2>Свяжитесь с нами!</h2>
            </div>
            <div class="row">
                <div class="left">
                    <div class="caption">
                        <span>Заполните форму и наш менеджер<br>свяжется с Вами.</span>
                    </div>
                    <ul class="soc-link" v-if="!isMobyle">
                        <li>
                            <a href="#">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.75 12.5L13.75 17.5L21.25 25L26.25 5L3.75 13.75L8.75 16.25L11.25 23.75L15 18.75"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span>@sapphirebet_partners_support</span>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:partners@sapphirebet.com">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.75 8.75C3.75 8.08696 4.01339 7.45107 4.48223 6.98223C4.95107 6.51339 5.58696 6.25 6.25 6.25H23.75C24.413 6.25 25.0489 6.51339 25.5178 6.98223C25.9866 7.45107 26.25 8.08696 26.25 8.75V21.25C26.25 21.913 25.9866 22.5489 25.5178 23.0178C25.0489 23.4866 24.413 23.75 23.75 23.75H6.25C5.58696 23.75 4.95107 23.4866 4.48223 23.0178C4.01339 22.5489 3.75 21.913 3.75 21.25V8.75Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.75 8.75L15 16.25L26.25 8.75" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span>partners@sapphirebet.com</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <form @submit.prevent="send()" id="reg-form">
                        <v-text-field v-model="form.name" autocomplete="off" outlined class="input"
                            :class="{ error: errors.name }" placeholder="Имя"></v-text-field>
                        <v-text-field v-model="form.email" autocomplete="off" outlined class="input"
                            :class="{ error: errors.email }" placeholder="E-mail"></v-text-field>
                        <v-text-field v-model="form.tg" autocomplete="off" outlined class="input"
                            :class="{ error: errors.tg }" placeholder="Telegram"></v-text-field>
                        <button class="btn" type="submit" :disabled="disabled">
                            Отправить
                        </button>
                    </form>
                    <ul class="soc-link" v-if="isMobyle">
                        <li>
                            <a href="https://t.me/@sapphirebet_partners_support" target="_blank">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.75 12.5L13.75 17.5L21.25 25L26.25 5L3.75 13.75L8.75 16.25L11.25 23.75L15 18.75"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span>@sapphirebet_partners_support</span>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:partners@sapphirebet.com" target="_blank">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.75 8.75C3.75 8.08696 4.01339 7.45107 4.48223 6.98223C4.95107 6.51339 5.58696 6.25 6.25 6.25H23.75C24.413 6.25 25.0489 6.51339 25.5178 6.98223C25.9866 7.45107 26.25 8.08696 26.25 8.75V21.25C26.25 21.913 25.9866 22.5489 25.5178 23.0178C25.0489 23.4866 24.413 23.75 23.75 23.75H6.25C5.58696 23.75 4.95107 23.4866 4.48223 23.0178C4.01339 22.5489 3.75 21.913 3.75 21.25V8.75Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.75 8.75L15 16.25L26.25 8.75" stroke="white" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span>partners@sapphirebet.com</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <img src="../../assets/sap-s-6_2.png" class="sap-2" v-if="!isMobyle">
    </section>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    name: 'Section6Com',
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
        link() {
            return 'https://sapphirebet.partners/sign-up/' + window.location.search
        }
    },
    data() {
        return {
            errors: {
                name: null,
                email: null,
                tg: null,
            },
            form: {
                name: null,
                email: null,
                tg: null,
            },
            disabled: false,
        }
    },
    methods: {
        send() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            let haveError = false
            if (!this.form.email || !emailPattern.test(this.form.email)) {
                haveError = true
                this.$set(this.errors, 'email', true)
            }
            if (!this.form.name) {
                haveError = true
                this.$set(this.errors, 'name', true)
            }
            if (!this.form.tg) {
                haveError = true
                this.$set(this.errors, 'rg', true)
            }
            if (haveError == false) {
                this.disabled = true
                axios({
                    method: "post",
                    url: "https://sapphirebet.info/candy_api/new_partners",
                    // url: "http://localhost:8094/send",
                    data: this.form,
                    headers: {
                        "content-type": "application/json",
                    },
                }).then(result => {
                    console.log(result)
                    this.form = {
                        name: null,
                        email: null,
                        tg: null,
                    }
                })
                    .catch(error => {
                        this.disabled = false
                        console.log(11111111)
                        console.log(11111111)
                        console.log(11111111)
                        
                        console.log(error.response)
                        // if (
                        //     error.response &&
                        //     error.response.data &&
                        //     error.response.data.status == 403
                        // ) {
                        //     if (
                        //         error.response.data.data &&
                        //         error.response.data.data.message
                        //     ) {
                        //         this.sendError = error.response.data.data.message
                        //     } else {
                        //         this.sendError = 'Register failed'
                        //     }
                        //     this.sendError = error?.response?.data?.data?.message || 'Register failed'
                        // } else {
                        //     this.sendError = 'Register failed'
                        // }
                        // console.log('error', error.response)
                    });
            }

        }
    }
}
</script>
<style lang="scss">
#sec-6 {
    padding: 0 20px;
    position: relative;
    z-index: 5;
    padding-top: 200px;
    padding-bottom: 500px;
    // background-image: url("../../assets/Frame 35-2.png");

    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;

    @media screen and (max-width: 768px) {
        overflow-x: hidden;
    }

    // @media screen and (max-width: 576px) {
    //     padding-top: 120px;
    //     background-image: url("../../assets/f-bg-m.png");
    //     padding-bottom: 550px;
    // }

    .sap-1 {
        position: absolute;
        z-index: 1;
        right: -20px;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .caption {

        h2,
        span {
            text-align: left;
            justify-content: flex-start;
            display: flex;
        }
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 576px) {
            flex-direction: column;
        }

        .soc-link {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 37px;

                    @media screen and (max-width: 576px) {
                        margin-bottom: 28px;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    svg {
                        margin-right: 20px;
                    }
                }
            }
        }

        .left {
            widows: 538px;
            max-width: 50%;

            @media screen and (max-width: 576px) {
                width: 100%;
                max-width: 100%;
            }

            .soc-link {
                margin-top: 173px;
            }
        }

        .right {
            width: 538px;
            max-width: 50%;

            @media screen and (max-width: 576px) {
                width: 100%;
                max-width: 100%;
            }

            .soc-link {
                margin-top: 76px;
            }

            form {
                width: 100%;

                .input {
                    width: 100%;
                    color: #fff !important;
                    margin-bottom: 20px;

                    .v-input__control,
                    .v-input__slot {
                        // height: 40px !important;
                        // max-height: 40px !important;
                        // min-height: 40px !important;
                    }

                    * {
                        border-color: #fff !important;
                        border-width: 2px !important;
                        color: #fff !important;
                        border-radius: 40px !important;
                        font-size: 20px !important;
                        font-style: normal !important;
                        font-weight: 400 !important;
                        line-height: normal !important;

                        @media screen and (max-width: 576px) {
                            font-size: 16px !important;
                        }
                    }

                    *::placeholder {
                        color: #9F9FB8 !important;
                        text-overflow: ellipsis !important;
                    }
                }

                .v-text-field__details {
                    display: none !important;
                }

                .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
                .v-text-field.v-text-field--enclosed .v-text-field__details {
                    padding: 20px 30px;
                    color: #fff;
                }

                .btn {
                    width: 100%;
                    padding: 20px 30px !important;
                    height: unset;
                    color: #172B9B !important;
                    font-size: 25px !important;
                    font-style: normal !important;
                    font-weight: 700 !important;
                    line-height: normal !important;
                }
            }
        }
    }

    .sap-2 {
        position: absolute;
        z-index: 1;
        left: -20px;
        bottom: 18%;
    }

    .error {
        color: red;
    }

}
</style>
<!-- partner-land@sapphire-bet.iam.gserviceaccount.com -->