<template>
    <div ref="container" id="diamond" class="diamond-container"></div>
</template>
  
<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DirectionalLight, HemisphereLight } from 'three';
// import { WEBGL } from "three/examples/jsm/WebGL.js";
import ScrollMagic from 'scrollmagic';
// import { TimelineMax } from 'gsap';//TweenLite
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
import { mapState } from 'vuex'

export default {
    name: 'DiamondDoraAnimation',
    data() {
        return {
            modelIsLoad: false,
            renderer: null,
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
    },
    mounted() {
        this.init()
        // this.initAnimation();
        // const warning = WEBGL.getWebGLErrorMessage();        
    },
    methods: {
        async init() {
            const self = this
            const renderer = new THREE.WebGLRenderer({ alpha: true });
            // renderer.shadowMap.enabled = false;
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight);
            // renderer.physicallyCorrectLights = true;
            renderer.domElement.setAttribute("id", "three-canvas");
            this.$refs.container.appendChild(renderer.domElement);

            const scene = new THREE.Scene();

            const fov = 3.5;
            const aspect = window.innerWidth / window.innerHeight;
            const near = 0.01;
            const far = 1000.0;

            const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
            camera.position.set(0, 50, 0);
            camera.lookAt(0, 0, 0);

            const light = new THREE.DirectionalLight(0xffffff);
            light.position.set(1, 1, 1);
            scene.add(light);

            const { ambientLight, mainLight } = this.createLights();
            scene.add(ambientLight, mainLight);

            let gltfmodel;
            const gltfLoader = new GLTFLoader();
            const controller = new ScrollMagic.Controller();

            await new Promise((resolve) => {


                gltfLoader.load("./diamond-3.glb", (gltf) => {
                    // gltfLoader.load("./test/source/untitled.glb", (gltf) => {
                    if (self.isMobyle) {
                        gltf.scene.position.set(0.5, 0, -0.5);
                    } else {
                        gltf.scene.position.set(2.2, 0, 0);
                    }
                    // gltf.scene.rotation.set(1.6, 0, 3.15);
                    // gltf.scene.rotation.set(1.37, 0, 3.3);
                    gltf.scene.rotation.set(1.4, 0, 3.3);
                    if (self.isMobyle) {
                        gltf.scene.scale.set(1.3 / 2, 0.9 / 2, 1 / 2);
                    } else {
                        gltf.scene.scale.set(1.3, 0.9, 1);
                    }

                    scene.add(gltf.scene);
                    gltfmodel = gltf.scene
                    scene.traverse((child) => {
                        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                            child.material.transparent = new THREE.MeshStandardMaterial()
                        }
                    })
                    camera.position.z = 0;

                    // const animation = new TimelineMax();
                    // animation.to(gltfmodel.rotation, 1, { y: Math.PI * 2 });


                    // Создание ScrollMagic сцены
                    // const scrollScene = 
                    new ScrollMagic.Scene({
                        triggerElement: this,
                        duration: "100%",
                        triggerHook: 0.25,
                    })
                        .setPin('#diamond')
                        // .setTween(animation)
                        .addTo(controller)
                        .on("update", function () {

                            const scrollDirection = controller.info("scrollDirection");
                            // var x2 = $(window).scrollTop();
                            // var x3 = 400;                            
                            let parcent = (window.pageYOffset / document.body.clientHeight) * 100
                            if (parcent > 0 && parcent < 17) {
                                let parcent2 = (parcent / 17) * 100
                                let offsetLeft
                                if (self.isMobyle) {

                                    offsetLeft = 0.5 / 100 * parcent2
                                } else {
                                    offsetLeft = 2.2 / 100 * parcent2
                                }
                                let v = (Math.PI * parcent2) / 100
                                let rotate = (Math.PI * parcent2) / 70

                                if (v < 0.34) {
                                    // gltfmodel.rotation.set(v, v, v);                            
                                    gltfmodel.rotation.set(1.4 - v, rotate, 3.3);
                                }
                                if (v > 1.7) {
                                    gltfmodel.rotation.y = rotate * 1.5

                                }
                                if (v > 2.5) {
                                    gltfmodel.rotation.x = v / 2
                                }
                                if (self.isMobyle) {
                                    gltfmodel.position.set(0.5 - offsetLeft, 0, -0.5);
                                } else {
                                    gltfmodel.position.set(2.2 - offsetLeft, 0, 0);
                                }

                            } else if (parcent > 18 && parcent < 32) {

                                let parcent2 = (parcent / 18) * 100
                                let rotateX = 32 - ((32 / parcent2) * 100)
                                if (rotateX < 0) {
                                    rotateX = 0
                                }
                                rotateX = rotateX / 50
                                let rotate = (Math.PI * parcent2) / 70
                                let offsetLeft = (2.2 / 100 * parcent2) - 1.7
                                // let v = (Math.PI * parcent2) / 100
                                if (offsetLeft < 2) {
                                    gltfmodel.position.set(0 + offsetLeft, 0, 0);
                                }
                                // console.log(v, rotate, parcent2, 32 - parcent3)
                                gltfmodel.rotation.y = rotate * 1.5
                                gltfmodel.rotation.x = 1 + rotateX
                                // gltfmodel.rotation.z = -(0 + rotateX)
                            } else if (parcent > 32 && parcent < 51.5) {
                                let parcent2 = (parcent / 34) * 100
                                let parcent3 = (parcent / 10) * 100
                                let rotate = (Math.PI * parcent2) / 70

                                let offsetLeft = 2 - ((2 / 100 * parcent3) - 6.40)
                                let scale = offsetLeft / 10
                                // console.log(scale)
                                // console.log(2 - offsetLeft)
                                gltfmodel.rotation.y = rotate - 100
                                // let offsetLeft = (2.2 / 100 * parcent2) - 1.7                                
                                gltfmodel.position.set(0 + offsetLeft, 0, 0);
                                if (self.isMobyle) {
                                    gltfmodel.scale.set((1.3 / 2) + scale, (0.9 / 2) + scale, (1 / 2) + scale);
                                } else {
                                    gltfmodel.scale.set(1.3 + scale, 0.9 + scale, 1 + scale);
                                }

                            } else if (parcent > 52 && parcent < 62) {
                                let parcent2 = (parcent / 52) * 100
                                let parcent3 = (parcent / 10) * 100
                                let parcent4 = parcent - 52

                                let offsetLeft = -2.8 + (525 + ((2 / 100 * parcent3) - 534))
                                let offsetX = ((1.4 * parcent4) / 100) / 50

                                let scale = ((1.4 * parcent4) / 100) / 50



                                let rotate = (Math.PI * parcent2) / 10
                                gltfmodel.rotation.y = rotate + 100

                                if (scrollDirection == 'REVERSE') {
                                    gltfmodel.rotation.x += offsetX
                                    gltfmodel.scale.z += scale
                                    gltfmodel.scale.y += scale
                                    gltfmodel.scale.x += scale
                                } else {
                                    gltfmodel.rotation.x -= offsetX
                                    gltfmodel.scale.z -= scale
                                    gltfmodel.scale.y -= scale
                                    gltfmodel.scale.x -= scale
                                }
                                // gltfmodel.rotation.z = rotate

                                gltfmodel.position.set(offsetLeft, 0, 0);

                                // gltfmodel.scale.x += scale
                                // gltfmodel.scale.y += scale
                                // gltfmodel.scale.z += scale
                                // console.log(scale, gltfmodel.scale.z)
                            } else if (parcent > 62 && parcent < 70) {
                                // let parcent2 = (parcent / 62) * 100 
                                let parcent3 = (parcent - 62) * 10
                                let parcent4 = (70 * parcent3) / 100
                                let offsetLeft2 = 0.6 - ((4.5 * parcent4) / 100)
                                let offsetX = ((1.4 * parcent4) / 100) / 100
                                let offsetY = ((1.4 * parcent4) / 100) / 50
                                let offsetZ = ((1.4 * parcent4) / 100) / 90
                                let scale = ((1.4 * parcent4) / 100) / 150

                                gltfmodel.position.set(offsetLeft2, 0, 0);
                                if (scrollDirection == 'REVERSE') {
                                    gltfmodel.rotation.x -= offsetX
                                    gltfmodel.rotation.y -= offsetY
                                    gltfmodel.rotation.z += offsetZ
                                    gltfmodel.scale.z -= scale
                                    gltfmodel.scale.y -= scale
                                    gltfmodel.scale.x -= scale
                                } else {
                                    gltfmodel.rotation.x += offsetX
                                    gltfmodel.rotation.y += offsetY
                                    gltfmodel.rotation.z -= offsetZ
                                    gltfmodel.scale.z += scale
                                    gltfmodel.scale.y += scale
                                    gltfmodel.scale.x += scale
                                }
                            } else if (parcent > 70) {
                                let parcent3 = (parcent - 70) * 10
                                let parcent4 = (70 * parcent3) / 100
                                let wWidth, offsetLeft2
                                if (self.isMobyle) {
                                    wWidth = -1
                                    offsetLeft2 = wWidth + ((4 * parcent4) / 100) - 2
                                    gltfmodel.position.set(offsetLeft2, 0, 1);
                                } else {
                                    wWidth = -2.2
                                    offsetLeft2 = wWidth + ((4 * parcent4) / 100)
                                    gltfmodel.position.set(offsetLeft2, 0, 0);
                                }

                                let offsetX = ((1.4 * parcent4) / 100) / 100
                                let offsetY = ((1.4 * parcent4) / 100) / 50
                                let offsetZ = ((1.4 * parcent4) / 100) / 90
                                let scale = ((1.4 * parcent4) / 100) / 300



                                if (scrollDirection == 'REVERSE') {
                                    gltfmodel.rotation.x -= offsetX
                                    gltfmodel.rotation.y -= offsetY
                                    gltfmodel.rotation.z += offsetZ
                                    gltfmodel.scale.z -= scale
                                    gltfmodel.scale.y -= scale
                                    gltfmodel.scale.x -= scale
                                } else {
                                    gltfmodel.rotation.x += offsetX
                                    gltfmodel.rotation.y += offsetY
                                    gltfmodel.rotation.z -= offsetZ
                                    gltfmodel.scale.z += scale
                                    gltfmodel.scale.y += scale
                                    gltfmodel.scale.x += scale
                                }

                            }

                            renderer.render(scene, camera);
                        })
                    // scrollScene
                    // const startAnimation = () => {
                    //     // Здесь можно запустить другие анимации с использованием diamondDora
                    //     // requestAnimationFrame(animate);
                    //     renderer.render(scene, camera);
                    //     this.$refs.container.appendChild(renderer.domElement);                
                    //     const controller = new ScrollMagic.Controller();
                    //     new ScrollMagic.Scene({
                    //         triggerElement: this.$refs.container,
                    //         triggerHook: 1,
                    //         duration: 1000,
                    //         offset: 50
                    //     })
                    //         .setTween(TweenLite.to(gltfmodel.rotation, 1, { x: 90, y: 0, z: 0 }))
                    //         .addTo(controller);

                    //     // const animate = () => {
                    //     //     requestAnimationFrame(animate);
                    //     //     renderer.render(scene, camera);
                    //     // };
                    //     // animate();
                    // }
                    // startAnimation()
                    resolve(true)
                })
            })
            const animate = () => {
                // requestAnimationFrame(animate);
                renderer.render(scene, camera);
            };

            animate();
        },
        createLights() {
            const ambientLight = new HemisphereLight(
                'white',
                'darkslategrey',
                5,
            );

            const mainLight = new DirectionalLight(0x1528a5, 20);
            mainLight.position.set(10, 50, 10);

            return { ambientLight, mainLight };
        },
        initAnimation() {
            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
            // camera.position.z = 5;
            camera.position.set(-5, 7, -5);
            camera.lookAt(new THREE.Vector3(0, 0, 0));
            scene.add(camera);

            const loader = new GLTFLoader();
            let diamondDora;

            // Загрузка 3D модели из папки public
            loader.load('./blue_diamond_-_free_giveaway/scene.gltf', (gltf) => {
                // loader.load('./blue_diamond_-_free_giveaway.glb', (gltf) => {
                diamondDora = gltf.scene;
                diamondDora.scale.set(2, 2, 2);
                diamondDora.position.y = 4;
                scene.add(diamondDora);
                const renderer = new THREE.WebGLRenderer();
                renderer.setSize(window.innerWidth, window.innerHeight);
                this.$refs.container.appendChild(renderer.domElement);
                renderer.render(scene, camera);
                // Запуск анимации после успешной загрузки модели
                // startAnimation(diamondDora);
            });

            // const startAnimation = (diamondDora) => {
            //     // Здесь можно запустить другие анимации с использованием diamondDora
            //     const renderer = new THREE.WebGLRenderer();
            //     renderer.setSize(window.innerWidth, window.innerHeight);
            //     this.$refs.container.appendChild(renderer.domElement);
            //     console.log(11111111)
            //     console.log(11111111)
            //     console.log(11111111)
            //     console.log(11111111)
            //     console.log(11111111)
            //     console.log(this.$refs.container)
            //     const controller = new ScrollMagic.Controller();
            //     new ScrollMagic.Scene({
            //         triggerElement: this.$refs.container,
            //         triggerHook: 1,
            //         duration: 1000,
            //         offset: 50
            //     })
            //         .setTween(TweenLite.to(diamondDora.rotation, 1, { x: 90, y: 0, z: 0 }))
            //         .addTo(controller);

            //     const animate = () => {
            //         requestAnimationFrame(animate);
            //         renderer.render(scene, camera);
            //     };
            //     animate();
            // }
        }
    },
};
</script>
  
<style>
.diamond-container {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
}
</style>
  