<template>
    <section id="sec-7">
    </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'Section7Com',
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
        link() {
            return 'https://sapphirebet.partners/sign-up/' + window.location.search
        }
    },
    data() {
        return {
            form: {
                name: null,
                email: null,
                tg: null,
            }
        }
    },
    methods: {
        send() {

        }
    }
}
</script>
<style lang="scss">
#sec-7 {
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-top: 200px;
    padding-bottom: 500px;
    background-image: url("../../assets/Frame 35-2.png");

    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;

    @media screen and (max-width: 768px) {
        overflow-x: hidden;
    }

    @media screen and (max-width: 576px) {
        padding-top: 120px;
        background-image: url("../../assets/f-bg-m.png");
        padding-bottom: 550px;
    }

}
</style>
  