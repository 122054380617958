<template>
  <div id="app">
    <MainPage />
    <YandexMetrica />
  </div>
</template>

<script>
import MainPage from './views/Main.vue'
import {debounce} from "lodash";
import YandexMetrica from "./components/YandexMetrica.vue";
export default {
  name: 'App',
  components: {
    MainPage,
    YandexMetrica
  },
  mounted() {
    this.updateWindowWidth = debounce(this.updateWindowWidth, 200)
    window.addEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.$store.commit('setWindowWidth', window.innerWidth)
    },
  }
}
</script>

<style lang="scss">
#app {}
</style>
