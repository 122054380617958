<template>
    <div id="landing">
        <DiamondDoraAnimation />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
    </div>
</template>
<script>
import Section1 from '../components/pageSections/Section1.vue'
import Section2 from '../components/pageSections/Section2.vue'
import Section3 from '../components/pageSections/Section3.vue'
import Section4 from '../components/pageSections/Section4.vue'
import Section5 from '../components/pageSections/Section5.vue'
import Section6 from '../components/pageSections/Section6.vue'
import Section7 from '../components/pageSections/Section7.vue'
import { mapState } from "vuex";
import DiamondDoraAnimation from '../components/DiamondDoraAnimation.vue'

export default {
    name: "MainPage",
    components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6,
        Section7,
        DiamondDoraAnimation,
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
    }
}
</script>
<style lang="scss">
#landing {
    background-repeat: repeat;

    @media screen and (max-width: 576px) {
        overflow-x: hidden;
    }
}
</style>
