<template>
    <section id="sec-4">
        <div class="container">
            <div class="caption">
                <h2>GAMBLING & BETTING</h2>
                <span>Принимаем трафик из Бразилии, Индии и Мексики со всех источников.</span>
            </div>
            <div class="slider">
                <swiper :options="swiperOptions">
                    <swiper-slide>
                        <div class="slide-content">
                            <img src="../../assets/img_brazil.png">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-content">
                            <img src="../../assets/img_mexico.png">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide-content">
                            <img src="../../assets/img_india.png">
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <!-- <div class="grid">
                <div class="grid__coll">
                    <img src="../../assets/img_brazil.png">
                </div>
                <div class="grid__coll">
                    <img src="../../assets/img_mexico.png">
                </div>
                <div class="grid__coll">
                    <img src="../../assets/img_india.png">
                </div>
            </div> -->
        </div>
        <img src="../../assets/sap-s-4_1.png" class="sap-1" v-if="!isTablet">
    </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'Section4Com',
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        isTablet() {
            return this.windowWidth <= 768
        },
        link() {
            return 'https://sapphirebet.partners/sign-up/' + window.location.search
        },
        swiperOptions() {
            return {
                loop: true,
                slidesPerView: this.isMobyle ? 1.2 : 3,
                spaceBetween: this.isMobyle ? 0 : 0, //15|20
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            }
        },
    },
    data() {
        return {
        }
    }
}
</script>
<style lang="scss">
#sec-4 {
    padding: 0 20px;
    position: relative;
    z-index: 5;
    padding-top: 200px;    

    @media screen and (max-width: 576px) {
        padding-top: 120px;
        padding: 0;
        padding-top: 200px;
    }

    .slider {
        @media screen and (max-width: 576px) {
            padding-left: 20px;
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 0px;
        position: relative;
        z-index: 2;

        &__coll {
            img {
                max-width: 100%;
            }

        }
    }

    .sap-1 {
        position: absolute;
        right: -20px;
        bottom: -20%;
        z-index: 1;
    }

    .slide-content {
        img {
            max-width: 100%;
        }
    }
}
</style>
  